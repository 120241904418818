import React, { useEffect } from 'react'

import { createStripeClient, injectStripeScript, isStripeInjected } from '@elo-kit/utils/stripe.utils'

import { useShopStores } from 'shop/hooks/use-store'

import { SETUP_FUTURE_USAGE, STRIPE_ELEMENTS_MODE, DEFAULT_STRIPE_AMOUNT } from 'constants/stripe.constants'
import { PAYMENT_FORMS, PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'

import { notify } from 'libs/common/notify'

interface Props {
  appleProvider: string
  applePubKey: string
  payOptions: {
    amount: number
    currency: string
  }
}

const APPLE_PAY_ONE_CLICK_ELEMENT_ID = 'express-apple-one-click-element'

export const ApplePayOneClick: React.FC<Props> = (props) => {
  const { appleProvider, applePubKey, payOptions } = props

  const { paymentStore, sellerStore } = useShopStores()

  useEffect(() => {
    if (isStripeInjected()) {
      initAppleExpress()
    } else {
      injectStripeScript(initAppleExpress)
    }
  }, [])

  const initAppleExpress = () => {
    const baseOptions = {
      mode: STRIPE_ELEMENTS_MODE.payment,
      amount: payOptions.amount || DEFAULT_STRIPE_AMOUNT,
      currency: payOptions.currency,
      setupFutureUsage: SETUP_FUTURE_USAGE.offSession,
    }

    const providerOptions =
      appleProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: sellerStore.item.elopageConnectAccountId } : {}

    const options = {
      ...baseOptions,
      ...providerOptions,
    }

    if (!applePubKey) return

    const stripeClient = createStripeClient(applePubKey)
    const elements = stripeClient.elements(options)

    const expressCheckoutElement = elements.create('expressCheckout', {
      paymentMethods: {
        googlePay: 'never',
        applePay: 'always',
      },
    })

    expressCheckoutElement.mount(`#${APPLE_PAY_ONE_CLICK_ELEMENT_ID}`)

    expressCheckoutElement.on('click', (event) => {
      paymentStore.handleTrackingExpressClick()

      const expressCountries = paymentStore.getAllowedExpressSellingCountries()

      const baseOptions = {
        shippingAddressRequired: true,
        shippingRates: [
          {
            id: 'free-shipping',
            displayName: I18n.t('react.shop.payment.free_shipping'),
            amount: 0,
          },
        ],
        emailRequired: true,
        phoneNumberRequired: true,
      }

      const countryOptions = expressCountries.length ? { allowedShippingCountries: expressCountries } : {}

      const options = {
        ...baseOptions,
        ...countryOptions,
      }

      event.resolve(options)
    })

    expressCheckoutElement.on('shippingaddresschange', async (event) => {
      await paymentStore.updateExpressPrice(event, elements)
    })

    expressCheckoutElement.on('confirm', async (event) => {
      const resp = await paymentStore.handleOneClickPayment(
        event.shippingAddress,
        event.billingDetails,
        PAYMENT_FORMS.applePay
      )

      if (resp?.clientSecret) {
        const { error } = await stripeClient.confirmPayment({
          elements,
          clientSecret: resp.clientSecret,
          confirmParams: {
            return_url: resp.redirectLink,
          },
        })

        if (error) {
          notify('error', error.message)
          paymentStore.hideLoading()
        }
      }
    })
  }

  return <div id={APPLE_PAY_ONE_CLICK_ELEMENT_ID} />
}
