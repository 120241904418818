import React from 'react'
import { observer } from 'mobx-react'

import { GooglePayOneClick } from 'shared/components/purchase-methods/GooglePayOneClick'
import { ApplePayOneClick } from 'shared/components/purchase-methods/ApplePayOneClick'
import { PurchaseTerms } from 'shop/components/payment/new/purchaseTerms'

import { convertPriceForStripe } from '@elo-kit/utils/stripe.utils'

import { useShopStores } from 'shop/hooks/use-store'

import { ExpressCheckoutWrapper } from './ExpressCheckoutWrapper'

export const OneClickPayment = observer(() => {
  const { sellerStore, paymentStore, cancellationTermsStore, themeStore } = useShopStores()

  return (
    <div className='express-checkout'>
      <div className='express-checkout__title'>{I18n.t('react.shared.express_checkout')}</div>
      <ExpressCheckoutWrapper>
        <div className='express-checkout__express-buttons'>
          {paymentStore.googleAvailability.googlePay && paymentStore.isGooglePayInPlan() && (
            <GooglePayOneClick
              googleProvider={sellerStore.item.googlePayProvider}
              googlePubKey={sellerStore.googlePublicKey}
              payOptions={{
                amount: convertPriceForStripe(paymentStore.totalPrice()),
                currency: paymentStore.currency,
              }}
            />
          )}
          {paymentStore.appleAvailability.applePay && paymentStore.isApplePayInPlan() && (
            <ApplePayOneClick
              appleProvider={sellerStore.item.applePayProvider}
              applePubKey={sellerStore.applePublicKey}
              payOptions={{
                amount: convertPriceForStripe(paymentStore.totalPrice()),
                currency: paymentStore.currency,
              }}
            />
          )}
        </div>
      </ExpressCheckoutWrapper>

      <div className='purchase express-checkout__purchase'>
        <PurchaseTerms
          isFree={false}
          link={paymentStore.product?.cancelLink}
          htmlTerms={cancellationTermsStore.item}
          checked={paymentStore.store?.terms}
          onCheckboxChange={(terms) => paymentStore.submitTerms(terms)}
          productType={paymentStore.product?.form}
          invoiceToken={paymentStore.invoice?.token}
          areHiddenCancellationTerms={
            paymentStore.payerFromType === 'business' && paymentStore.product?.hideB2bCancellationTerm
          }
          sellerUsername={sellerStore.item.username}
          isPowerSeller={sellerStore.item.powerSeller}
          usePowerSellerDocument={sellerStore.item.documentConfiguration?.termsOfBusiness}
          paymentPageColor={themeStore.ppTemplate?.theme?.paymentPageColor}
        />
      </div>
    </div>
  )
})
