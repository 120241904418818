import React from 'react'
import { observer } from 'mobx-react'
import { useShopStores } from 'shop/hooks/use-store'

interface Props {
  children: React.ReactNode
}

export const ExpressCheckoutWrapper = observer(({ children }: Props) => {
  const { cancellationTermsStore, paymentStore } = useShopStores()

  const isCheckboxVisible = cancellationTermsStore.item?.checkboxVisible
  const hasAcceptedTerms = paymentStore.store?.terms
  const shouldDisable = isCheckboxVisible && !hasAcceptedTerms

  return (
    <div
      className='express-checkout__buttons'
      style={{
        opacity: shouldDisable ? 0.5 : 1,
        pointerEvents: shouldDisable ? 'none' : 'auto',
      }}
    >
      {children}
    </div>
  )
})
