import React from 'react'
import { observer } from 'mobx-react'
import { useShopStores } from 'shop/hooks/use-store'

import { SummaryCoupon } from './summaryCoupon'
import { SummaryPrices } from './summaryPrices'
import { NewSummaryPrices } from './new-summary-prices/NewSummaryPrices'

interface Props {
  isTemplatePreview?: boolean
  iframeFree?: boolean
}

export const Summary: React.FC<Props> = observer(function Summary(props) {
  const { isTemplatePreview, iframeFree } = props
  const { paymentStore, themeStore, experimentsStore } = useShopStores()

  const newSummaryPricesExperiment = experimentsStore.getExperiment(
    'show_combined_pricing_plans_new_summary_payment_ui'
  )
  const isNewSummaryPrices = newSummaryPricesExperiment.get('isNewCheckoutUI', false)

  const buttonStyle = themeStore.getApplyCouponBtnStyle()

  const couponFormVisible =
    paymentStore.store?.props?.showCoupon || themeStore.ppTemplate.buyerInformation.couponField?.visible !== 'off'

  return (
    <div className='summary'>
      <div className='container-title'>{I18n.t('react.shop.payment.form.summary')}</div>
      {(couponFormVisible || isTemplatePreview) && (
        <SummaryCoupon buttonStyle={buttonStyle} isTemplatePreview={isTemplatePreview} iframeFree={iframeFree} />
      )}

      {isNewSummaryPrices ? (
        <NewSummaryPrices />
      ) : (
        <SummaryPrices isTemplatePreview={isTemplatePreview} iframeFree={iframeFree} />
      )}
    </div>
  )
})
