import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { EloCard } from '@elo-ui/components/elo-card'
import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloAccordion } from '@elo-ui/components/elo-accordion'
import { EloCheckCircleIcon } from '@elo-ui/components/icons/regular'

import { convertToPercents } from 'utils/helpersShared.utils'

import { useShopStores } from 'shop/hooks/use-store'

interface Props {
  isTemplatePreview: boolean
  buttonStyle: {
    backgroundColor: string
    color: string
    borderRadius: string
  }
  iframeFree?: boolean
}

export const SummaryCoupon: React.FC<Props> = observer(function SummaryCoupon(props) {
  const { buttonStyle, isTemplatePreview, iframeFree } = props
  const { paymentStore, currenciesStore, trackingUserEventsStore } = useShopStores()

  const [code, setCode] = useState('')
  const [couponError, setCouponError] = useState('')

  const handleCouponChange = (e) => {
    if (!!couponError.length) setCouponError('')
    setCode(e.target.value)
  }

  const applyCouponCode = async () => {
    const applyCouponError = await paymentStore.submitCoupon(code)
    if (applyCouponError) {
      setCouponError(applyCouponError as string)
    }
    trackingUserEventsStore.logEvent({
      pageType: 'shop_checkout',
      eventType: applyCouponError ? 'coupon_error_submit' : 'coupon_success_submit',
      payload: applyCouponError ? { couponError: applyCouponError, couponCode: code } : { couponCode: code },
    })
  }

  const removeCouponCode = () => {
    paymentStore.removeCoupon()
    setCode('')
  }

  const couponDataValue = paymentStore.store?.coupon?.data?.value
  const firstRate = paymentStore.buildedOrder?.orderRates?.[0]

  let discountSum = 0

  if (!paymentStore.buildedOrderLoading && firstRate) {
    discountSum = firstRate.orderRatePrices.reduce((acc, currentVal) => {
      const { cfgs, discount } = currentVal.data
      const priceKey = cfgs.mustPayVat ? 'gross' : 'net'
      const nextDiscountSum = discount ? discount[priceKey] * discount.count : 0

      return acc + nextDiscountSum
    }, 0)
  }

  const couponValue = paymentStore.store?.coupon?.data?.percents
    ? convertToPercents(couponDataValue)
    : currenciesStore.convertToPrice(couponDataValue, paymentStore.store?.activePlan?.currencyId)

  const getAccordionItems = () => [
    {
      headline: I18n.t('react.shop.payment.coupon.label'),
      content: (
        <div className='coupon-check-content'>
          <EloInput
            label={I18n.t('react.shop.payment.coupon.promo_code')}
            placeholder={I18n.t('react.shop.payment.coupon.enter_code')}
            inputSize='large'
            error={!!couponError.length}
            errorText={couponError}
            onChange={handleCouponChange}
            onBlur={() =>
              trackingUserEventsStore.logEvent({
                pageType: 'shop_checkout',
                eventType: 'coupon_input_blur',
                payload: { couponCode: code },
              })
            }
          />
          <EloButton style={buttonStyle} disabled={!code.length} onClick={applyCouponCode}>
            {I18n.t('react.shared.button.apply')}
          </EloButton>
        </div>
      ),
    },
  ]

  return (
    <>
      {((!iframeFree && !paymentStore.store?.free && paymentStore.store?.coupon?.form) || isTemplatePreview) && (
        <div className='coupon-check'>
          <EloAccordion
            items={getAccordionItems()}
            size='medium'
            onItemToggle={(_, isOpen) =>
              trackingUserEventsStore.logEvent({
                pageType: 'shop_checkout',
                eventType: 'coupon_view_change',
                payload: { couponViewIsOpen: isOpen },
              })
            }
          />
        </div>
      )}
      {paymentStore.store?.coupon?.applied && (
        <div className='coupon-applied'>
          <EloCard type='informational' contentClassName='coupon-applied-content' selected>
            <div>
              <EloCheckCircleIcon size={40} color='#05AB5B' />
              <div>
                <div>
                  <span className='coupon-applied-content__code'>{paymentStore.store?.coupon?.code || ''}</span>
                  <span>{I18n.t('shared.common.applied').toLowerCase()}</span>
                </div>
                {discountSum !== 0 && (
                  <>
                    <div className='coupon-applied-content__details'>
                      {`${couponValue} ${I18n.t('react.shared.off')}`}
                    </div>
                    {paymentStore.store?.coupon?.data?.recurring && paymentStore.store?.withNextPayment && (
                      <div className='coupon-applied-content__details'>
                        {I18n.t('react.shop.payment.coupon.info_recurring_new')}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <EloButton variant='secondary' onClick={removeCouponCode}>
              {I18n.t('react.shared.button.remove')}
            </EloButton>
          </EloCard>
        </div>
      )}
    </>
  )
})
