import React, { Fragment } from 'react'

import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloStatusBadge } from '@elo-ui/components/elo-status-badge'
import { EloCheckCircleIcon } from '@elo-ui/components/icons/regular'
import { EloProductDisplay } from '@elo-ui/components/elo-product-display'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'
import { EloButton } from '@elo-ui/components/elo-button'

import { convertToPercents } from 'utils/helpersShared.utils'
import { getFirstCoverUrl } from 'utils/product.utils'
import { getTimePeriodLocale } from 'shop/utils/dateTime.utils'

import { COUPON_VALUE_FORM } from 'constants/coupons.constants'

import { CheckoutSummary } from 'shop/types/summary'

interface Props {
  setShowDetailsModal: (value: boolean) => void
  newCheckoutSummary: CheckoutSummary
  convertToPrice: (value: string, currencyId: number) => string
  getFormattedPrice: (price: number) => string
  shortSummary: React.ReactNode
}

export const NewSummaryDetailsModal: React.FC<Props> = (props) => {
  const { setShowDetailsModal, newCheckoutSummary, convertToPrice, getFormattedPrice, shortSummary } = props

  return (
    <EloModal
      isOpen
      onClose={() => setShowDetailsModal(false)}
      className='new-summary-prices-modal'
      dataTestId='details-modal'
    >
      <EloModalHeader>{I18n.t('react.shop.payment.summary_modal.title')}</EloModalHeader>
      <EloModalBody>
        <div>
          {newCheckoutSummary.sellables.map((sellable, index) => {
            const {
              dueToday,
              dueTodayDiscounted,
              dueTodayVat,
              dueTodayDiscountedVat,
              endDateOfTheTrialPeriod,
              firstPaymentStartDay,
              subtotal,
              subtotalDiscounted,
              subtotalVat,
              subtotalDiscountedVat,
              subtotalPaymentsCount,
              additionalFeePrice,
              additionalFeeName,
              additionalFeeIncluded,
              coupon,
              recurringPayments,
              priceCount,
              productName,
              productForm,
              vatPercentage,
              isLimitedSubscription,
              isInstallment,
              isSubscription,
              withTrialPeriod,
              withCoupon,
              withCustomStartDay,
            } = sellable

            const showDueToday = withTrialPeriod || withCustomStartDay || isSubscription
            const isCouponInPercents = coupon?.valueForm === COUPON_VALUE_FORM.percents

            return (
              <Fragment key={index}>
                {withCoupon && (
                  <div className='new-summary-prices__coupon'>
                    <EloStatusBadge
                      highlight={false}
                      badgeColor='var(--color-highlight-web)'
                      icon={<EloCheckCircleIcon />}
                      className='new-summary-prices__coupon--applied'
                    >
                      <span>
                        {`${
                          isCouponInPercents
                            ? convertToPercents(Number(coupon.value), 0)
                            : convertToPrice(coupon.value, newCheckoutSummary.currencyId)
                        } ${I18n.t('react.shared.off')}`}
                      </span>
                    </EloStatusBadge>
                  </div>
                )}
                <div className='new-summary-prices-modal__product'>
                  {priceCount > 1 && <div>{priceCount}x</div>}
                  <EloProductDisplay name={productName} image={getFirstCoverUrl(sellable)} type={productForm} />
                </div>
                {showDueToday && (
                  <div className='new-summary-prices-modal__today'>
                    <div className='new-summary-prices-modal__today--label'>
                      {I18n.t('react.shop.payment.due_today')}
                    </div>
                    <EloPriceDisplay
                      price={getFormattedPrice(dueTodayDiscounted)}
                      oldPrice={withCoupon ? getFormattedPrice(dueToday) : ''}
                      size='extra-small'
                    />
                  </div>
                )}
                {!showDueToday && (isLimitedSubscription || isInstallment) && (
                  <>
                    <div className='new-summary-prices-modal__today'>
                      <div className='new-summary-prices-modal__today--label'>
                        {I18n.t('react.shop.payment.due_today')}
                      </div>
                      <EloPriceDisplay
                        price={getFormattedPrice(dueTodayDiscounted)}
                        oldPrice={withCoupon ? getFormattedPrice(dueToday) : ''}
                        size='extra-small'
                      />
                    </div>
                    <div className='new-summary-prices-modal__vat'>
                      <div className='new-summary-prices-modal__vat--label'>
                        {I18n.t('react.shop.show.price_include')} {vatPercentage}%
                      </div>
                      <EloPriceDisplay
                        price={getFormattedPrice(dueTodayDiscountedVat)}
                        oldPrice={withCoupon ? getFormattedPrice(dueTodayVat) : ''}
                        size='extra-small'
                      />
                    </div>
                  </>
                )}
                {withTrialPeriod && (
                  <div className='new-summary-prices-modal__test-period'>
                    <div className='new-summary-prices-modal__test-period--label'>
                      {I18n.t('react.shop.payment.end_of_trial')}
                    </div>
                    <div className='new-summary-prices-modal__test-period--date'>{endDateOfTheTrialPeriod}</div>
                  </div>
                )}
                {withCustomStartDay && (
                  <div className='new-summary-prices-modal__custom-date'>
                    <div className='new-summary-prices-modal__custom-date--label'>
                      {I18n.t('react.shared.pricing_plan.first_payment')}
                    </div>
                    <div className='new-summary-prices-modal__custom-date--date'>{firstPaymentStartDay}</div>
                  </div>
                )}

                {isSubscription ? (
                  recurringPayments.map((item, index) => (
                    <Fragment key={index}>
                      <div className='new-summary-prices-modal__subtotal'>
                        <div className='new-summary-prices-modal__subtotal--label'>
                          {index ? I18n.t('react.shared.subscription_from') : I18n.t('react.shared.due')} {item.date}
                        </div>
                        <EloPriceDisplay
                          price={getFormattedPrice(item.discountedAmount)}
                          oldPrice={
                            withCoupon && item.discountedAmount !== item.amount ? getFormattedPrice(item.amount) : ''
                          }
                          size='extra-small'
                          supportiveText={index && getTimePeriodLocale(item.interval)}
                        />
                      </div>
                      <div className='new-summary-prices-modal__vat'>
                        <div className='new-summary-prices-modal__vat--label'>
                          {I18n.t('react.shop.show.price_include')} {vatPercentage}%
                        </div>
                        <EloPriceDisplay
                          price={getFormattedPrice(item.discountedVat)}
                          oldPrice={withCoupon && item.discountedVat !== item.vat ? getFormattedPrice(item.vat) : ''}
                          size='extra-small'
                          supportiveText={index && getTimePeriodLocale(item.interval)}
                        />
                      </div>
                    </Fragment>
                  ))
                ) : (
                  <>
                    <div className='new-summary-prices-modal__subtotal'>
                      <div className='new-summary-prices-modal__subtotal--label'>
                        {isLimitedSubscription || isInstallment
                          ? I18n.t('react.shop.payment.total_payments', { count: subtotalPaymentsCount })
                          : I18n.t('react.shared.payment.amount')}
                      </div>
                      <EloPriceDisplay
                        price={getFormattedPrice(subtotalDiscounted)}
                        oldPrice={withCoupon ? getFormattedPrice(subtotal) : ''}
                        size='extra-small'
                      />
                    </div>
                    <div className='new-summary-prices-modal__vat'>
                      <div className='new-summary-prices-modal__vat--label'>
                        {I18n.t('react.shop.show.price_include')} {vatPercentage}%
                      </div>
                      <EloPriceDisplay
                        price={getFormattedPrice(subtotalDiscountedVat)}
                        oldPrice={withCoupon ? getFormattedPrice(subtotalVat) : ''}
                        size='extra-small'
                      />
                    </div>
                  </>
                )}
                {additionalFeeIncluded && (
                  <div className='new-summary-prices-modal__fee'>
                    <div className='new-summary-prices-modal__fee--label'>
                      {additionalFeeName || I18n.t('react.shop.payment.fee_amount')}
                    </div>
                    <EloPriceDisplay withPlusSymbol price={getFormattedPrice(additionalFeePrice)} size='extra-small' />
                  </div>
                )}
                <div className='new-summary-prices-modal__separator' />
              </Fragment>
            )
          })}
        </div>
        <div>{shortSummary}</div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--center'>
        <EloButton onClick={() => setShowDetailsModal(false)} variant='secondary'>
          {I18n.t('react.shared.button.close')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
}
