import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react'

import { EloStatusBadge } from '@elo-ui/components/elo-status-badge'
import { EloCheckCircleIcon } from '@elo-ui/components/icons/regular'
import { EloPriceDisplay } from '@elo-ui/components/elo-price-display'
import { EloLink } from '@elo-ui/components/elo-link'
import I18n from '@elo-kit/components/i18n'

import { convertToPercents } from 'utils/helpersShared.utils'
import { getTimePeriodLocale } from 'shop/utils/dateTime.utils'
import { createFormattedDate } from 'utils/dateTime.utils'

import { useShopStores } from 'shop/hooks/use-store'

import { NewSummaryDetailsModal } from './details-modal/NewSummaryDetailsModal'

import './new-summary-prices.scss'

export const NewSummaryPrices = observer(() => {
  const [showDetailsModal, setShowDetailsModal] = useState(false)

  const { currenciesStore, paymentStore, trackingUserEventsStore, countriesStore } = useShopStores()
  const { newCheckoutSummary } = paymentStore

  const getFormattedPrice = (amount: number) => currenciesStore.convertToPrice(amount, newCheckoutSummary.currencyId)

  const productCouponLabel = I18n.t('react.shop.payment.product', {
    count: newCheckoutSummary.couponSummary[0]?.productCount,
  })

  const hideTotal = newCheckoutSummary.sellables.length === 1 && !!newCheckoutSummary.recurringPaymentSums.length

  const shortSummary = useMemo(
    () => (
      <>
        <div className='new-summary-prices__total-today'>
          <div className='new-summary-prices__total-today--label'>{I18n.t('react.shop.payment.due_today')}</div>
          <EloPriceDisplay
            price={getFormattedPrice(newCheckoutSummary.dueTodayDiscountedSum)}
            oldPrice={
              newCheckoutSummary.withCoupon && newCheckoutSummary.dueTodaySum
                ? getFormattedPrice(newCheckoutSummary.dueTodaySum)
                : ''
            }
          />
        </div>
        {!hideTotal && (
          <div className='new-summary-prices__total'>
            <div className='new-summary-prices__total--label'>{I18n.t('react.shop.payment.total')}</div>
            <EloPriceDisplay
              price={getFormattedPrice(newCheckoutSummary.totalDiscountedSum)}
              oldPrice={newCheckoutSummary.withCoupon ? getFormattedPrice(newCheckoutSummary.totalSum) : ''}
              size='extra-small'
            />
          </div>
        )}

        {newCheckoutSummary.recurringPaymentSums?.map((item, index) => {
          const today = createFormattedDate(new Date())
          const showNextPayment = !index && item.date !== today
          const showSubsPayments = index || showNextPayment

          return (
            showSubsPayments && (
              <div className='new-summary-prices__total' key={index}>
                <div className='new-summary-prices__total--label'>
                  {index
                    ? `${I18n.t('react.shared.subscription_from')} ${item.date}`
                    : `${I18n.t('react.shop.payment.summary.next_payment')} (${item.date})`}
                </div>
                <EloPriceDisplay
                  price={getFormattedPrice(item.totalDiscounted)}
                  oldPrice={
                    newCheckoutSummary.withCoupon && item.totalDiscounted !== item.total
                      ? getFormattedPrice(item.total)
                      : ''
                  }
                  size='extra-small'
                  supportiveText={index && getTimePeriodLocale(item.interval)}
                />
              </div>
            )
          )
        })}

        {newCheckoutSummary.vatIncluded && (
          <div className='new-summary-prices__vat'>
            {newCheckoutSummary.isReverseCharge
              ? I18n.t('react.shop.payment.excludes_vat')
              : I18n.t('react.shop.payment.includes_vat')}
          </div>
        )}

        {newCheckoutSummary.additionalFeeIncluded && (
          <div className='new-summary-prices__fees'>{I18n.t('react.shop.payment.includes_fees')}</div>
        )}

        {newCheckoutSummary.vatText && (
          <div
            className='new-summary-prices__vat-text'
            dangerouslySetInnerHTML={{ __html: newCheckoutSummary.vatText }}
          />
        )}

        {newCheckoutSummary.isReverseCharge && (
          <div className='new-summary-prices__reverse-vat-text'>
            {I18n.t('react.shop.payment.reverse_charge', {
              rate: `${newCheckoutSummary.reverseChargeVatPercentage}%`,
              amount: getFormattedPrice(newCheckoutSummary.totalDiscountedVatSum),
              country: countriesStore.getCountry(newCheckoutSummary.vatCountry).name,
            })}
          </div>
        )}
      </>
    ),
    [
      newCheckoutSummary.dueTodayDiscountedSum,
      newCheckoutSummary.totalDiscountedSum,
      JSON.stringify(newCheckoutSummary.recurringPaymentSums),
      newCheckoutSummary.vatIncluded,
      newCheckoutSummary.additionalFeeIncluded,
      newCheckoutSummary.vatText,
      newCheckoutSummary.isReverseCharge,
    ]
  )

  return (
    <div className='new-summary-prices'>
      {newCheckoutSummary.withCoupon && (
        <div className='new-summary-prices__coupon'>
          <EloStatusBadge
            highlight={false}
            badgeColor='var(--color-highlight-web)'
            icon={<EloCheckCircleIcon />}
            className='new-summary-prices__coupon--applied'
          >
            <span>
              {`${
                paymentStore.isCouponInPercents
                  ? convertToPercents(Number(newCheckoutSummary.couponSummary[0].value), 0)
                  : currenciesStore.convertToPrice(
                      newCheckoutSummary.couponSummary[0].value,
                      newCheckoutSummary.currencyId
                    )
              } ${I18n.t('react.shared.off')}`}
            </span>
          </EloStatusBadge>
          <span className='new-summary-prices__coupon--label'>{productCouponLabel}</span>
        </div>
      )}
      <div className='new-summary-prices__prices-info'>{shortSummary}</div>
      <div className='new-summary-prices__details-link'>
        <EloLink
          as='span'
          onClick={() => {
            setShowDetailsModal(true)
            trackingUserEventsStore.logEvent({
              pageType: 'shop_checkout',
              eventType: 'extend_checkout_summary_click',
              withExperimentDetails: true,
            })
          }}
        >
          {I18n.t('react.shared.view_details')}
        </EloLink>
      </div>

      {showDetailsModal && (
        <NewSummaryDetailsModal
          setShowDetailsModal={setShowDetailsModal}
          newCheckoutSummary={newCheckoutSummary}
          convertToPrice={currenciesStore.convertToPrice}
          getFormattedPrice={getFormattedPrice}
          shortSummary={shortSummary}
        />
      )}
    </div>
  )
})
